<template>
  <div
    id="appApage"
    class="bg-dark"
  >
    <cookie />
    <router-view />
    <playback />
    <page-footer />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Playback from '@/components/InDevelopment/Playback.vue';
import Cookie from '@/components/Cookie.vue';
import PageFooter from '@/components/Allgemein/PageFooter.vue';

export default defineComponent({
  name: 'Impressum',
  components: {
    PageFooter,
    Playback,
    Cookie
  }
});

</script>

<style lang="scss" >
  @import "style/spectre_custom";

  // Import full Spectre source code
  @import "node_modules/spectre.css/src/spectre";
  @import "node_modules/spectre.css/src/spectre-exp";
  @import "node_modules/spectre.css/src/spectre-icons";

  @import "style/agape_panels";

  body {
    min-height: 100%;
  }

  #app {
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
